@font-face {
    font-family: 'Walibi';
    src: local('MyFont'), url(./fonts/walibi-brand.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Avenir';
    src: local('MyFont'), url(./fonts/walibi-avenir.ttf) format('truetype');
  }

  
  @font-face {
    font-family: 'Avenir Black';
    src: local('MyFont'), url(./fonts/walibi-avenir-black.ttf) format('truetype');
  }